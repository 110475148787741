<template>
  <div class="mx-5">
    شاشة تسجيل اشتراك جديد
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>